<template>
  <div class="tools-overlay">
    <div class="tools-search" v-draggable="draggableOptions" @mousedown.stop>
      <div class="tools-handle" id="tools-handle">
        <a-icon type="search"></a-icon>
      </div>
      <div class="tools-pop" :class="{ popRight: toolsPop.popRight }" v-show="searchShow">
        <div class="tools-pop-bd">
          <div class="search-bar" :class="{ active: true }">
            <div class="search-input-container">
              <div class="search-input">
                <a-input
                  v-model="searchText"
                  autocomplete="off"
                  placeholder="请输入关键字查询"
                  @keypress.enter="search"
                  @focus="toolsPop.inputFocus = true"
                  @blur="toolsPop.inputFocus = false"
                  @input="input"
                  ref="searchInput"
                />
              </div>
              <div class="search-btn" @click="search">
                <a-icon type="search"></a-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Draggable } from '@/utils/drag'

export default {
  directives: {
    Draggable,
  },
  data() {
    return {
      searchShow: false,
      searchText: '',
      draggableOptions: {
        onDragStart: this.onDragStart,
        onDragMove: this.onDragMove,
        onDragEnd: this.onDragEnd,
        moveElId: 'tools-handle',
      },
      dragStartData: {
        top: 0,
        left: 0,
        countTime: 0,
      },
      toolsPop: {
        popRight: false,
        inputFocus: false,
      },
    }
  },
  methods: {
    getElementLeft(element) {
      var actualLeft = element.offsetLeft
      var current = element.offsetParent

      while (current !== null) {
        actualLeft += current.offsetLeft
        current = current.offsetParent
      }

      return actualLeft
    },
    setPopDirection(pos) {
      if (pos.left < this.$el.offsetWidth / 2 - 30) {
        this.toolsPop.popRight = true
      } else {
        this.toolsPop.popRight = false
      }
    },
    onDragMove(e, pos) {
      if (!this.searchShow) {
        return
      }
      this.setPopDirection(pos)
    },
    onDragStart(move, pos) {
      this.dragStartData.countTime = new Date().getTime()
      this.dragStartData.top = pos.top
      this.dragStartData.left = pos.left
    },
    onDragEnd(move, pos) {
      if (
        new Date().getTime() - this.dragStartData.countTime < 600 &&
        this.dragStartData.top === pos.top &&
        this.dragStartData.left === pos.left
      ) {
        this.setPopDirection(pos)
        this.togglePop()
      }
      this.dragStartData.countTime = 0
      this.dragStartData.left = 0
      this.dragStartData.top = 0
    },
    togglePop() {
      this.searchShow ? this.closeSearch() : this.openSearch()
    },
    openSearch() {
      this.searchShow = true
      this.$nextTick(() => {
        this.$refs.searchInput.focus()
      })
      // 使用mousedown 可以避免按住鼠标勾选内容时不小心点到外面导致的意外关闭
      document.body.addEventListener('mousedown', this.closeSearch)
    },
    closeSearch() {
      this.searchShow = false
      document.body.removeEventListener('mousedown', this.closeSearch)
    },
    search() {
      this.$emit('search', this.searchText)
    },
    input() {
      this.$emit('input', this.searchText)
    },
  },
}
</script>

<style lang="less" scoped>
.tools-overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99999;
  pointer-events: none;
  overflow: hidden;
}
.tools-search {
  position: absolute;
  bottom: 100px;
  right: 100px;
  width: 60px;
  height: 60px;
  background: #639bfc;
  z-index: 100;
  border-radius: 45px;
  display: flex;
  align-items: center;
  box-shadow: 0px 5px 10px 0px rgba(153, 153, 153, 0.4);
  pointer-events: initial;
  padding-left: 15px;
  .tools-handle {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    color: #fff;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  &:hover {
    box-shadow: 0px 5px 10px 0px rgba(153, 153, 153, 0.7);
  }
  .tools-pop {
    position: absolute;
    width: 400px;
    height: 60px;
    top: 0;
    left: 0;
    margin-left: -30px;
    transform: translateX(-100%) translateY(0px);
    box-shadow: 0px 5px 10px 0px rgba(153, 153, 153, 0.4);
    background: #fff;
    border: 1px solid #eee;
    color: #30425b;
    &.popRight {
      transform: translateX(90px) translateY(0px);
      margin-left: 0;
    }
    .tools-pop-hd {
      display: flex;
      justify-content: space-between;
      padding: 13px 20px;
    }
    .tools-pop-bd {
      padding: 12px 20px;
    }
    .actions {
      .anticon {
        &:hover {
          color: #639bfc;
        }
      }
    }
  }
  // &.opened {

  // }
  .search-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 36px;
    background: #eee;
    border-radius: 4px;

    &.active {
      background: #fff;
      .search-btn {
        background: #639bfc;
        color: #fff;
        cursor: pointer;
      }
      border: 1px solid #639bfc;

      .search-input-container {
        .ant-input {
          background: #fff;
        }
      }
    }
    .search-input-container {
      align-items: center;
      height: 34px;
      overflow: hidden;
      flex: 1;
      .ant-input {
        background: #eee;
        border: 0;
        box-shadow: none;
      }
      position: relative;
    }
    .search-btn {
      position: absolute;
      right: 0px;
      top: 0px;
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
